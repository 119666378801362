<template>
  <div class="app-container" :class="{ 'desktop': isDesktop , 'is-ios': isIOS}">
    <router-view/>
  </div>
</template>


<script>
export default {
  computed: {
    isDesktop() {
      const {desktop} = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  }
}
</script>



