<template>
  <div class="appt-type-card" :class="{ active: selected }">
    <div class="appt-type-icon-wrapper">
      <svg
        v-if="type === 'drop_off'"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.1362 1.77853C15.5563 1.99177 15.783 2.45876 15.6906 2.92076L14.2535 10.106H17.1933C17.6097 10.106 17.9868 10.3521 18.1544 10.7332C18.3221 11.1143 18.2487 11.5586 17.9673 11.8655L8.68207 21.9949C8.3637 22.3422 7.85296 22.4349 7.43283 22.2217C7.0127 22.0085 6.78604 21.5415 6.87844 21.0795L8.31549 13.8942H5.3757C4.95931 13.8942 4.58226 13.6482 4.41459 13.267C4.24692 12.8859 4.32032 12.4417 4.60169 12.1347L13.887 2.00533C14.2053 1.65801 14.7161 1.56529 15.1362 1.77853ZM7.7626 11.7942H9.59629C9.91086 11.7942 10.2089 11.9353 10.4083 12.1785C10.6077 12.4218 10.6876 12.7417 10.6259 13.0502L9.67518 17.8037L14.8064 12.206H12.9728C12.6582 12.206 12.3602 12.065 12.1607 11.8217C11.9613 11.5784 11.8814 11.2585 11.9431 10.9501L12.8939 6.1965L7.7626 11.7942Z"
          fill="currentColor"
        />
      </svg>

      <svg
        v-else-if="type === 'waiting'"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12C3.6 16.6392 7.36081 20.4 12 20.4ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2369 5.95312C11.8168 5.95312 12.2869 6.42323 12.2869 7.00313V11.9531H17.2369C17.8168 11.9531 18.2869 12.4232 18.2869 13.0031C18.2869 13.583 17.8168 14.0531 17.2369 14.0531H11.2369C10.657 14.0531 10.1869 13.583 10.1869 13.0031V7.00313C10.1869 6.42323 10.657 5.95312 11.2369 5.95312Z"
          fill="currentColor"
        />
      </svg>
    </div>

    <div class="appt-type-card-content">
      <div class="appt-type-card-title">{{ title }}</div>
      <div class="appt-type-card-description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppointmentTypeCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    type: {
      type: String, // 'drop_off' | 'waiting'
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>