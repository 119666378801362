import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ElementUI from 'element-ui';
import VCalendar from 'v-calendar';
import 'element-ui/lib/theme-chalk/index.css';
import httpClient from '@/client';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import '@/assets/scss/style.scss';

Vue.config.productionTip = false;

Vue.use(VueFormWizard);

Vue.use(ElementUI);

Vue.use(VCalendar);

Vue.prototype.$http_client = httpClient;

// Automatically register all icons in the components/icons folder
const requireComponent = require.context(
    // The relative path of the components folder
    './components/icons',
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);
    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    );

    Vue.component(
        `${componentName}Icon`,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
