import apiClient from '@/client';

export default {
    get: {
        recognisedCustomerVehicles: (shop, email, phone) => {
            return apiClient.get(`/public/accounts/${shop}/customer-vehicles`, {
                params: {
                    email,
                    phone
                }
            });
        }
    },
    post: {
        bookAppointment: (
            appointmentTime,
            appointmentType,
            selectedServiceSlug,
            selectedServiceAdditionalNotes,
            requestedServiceDescription,
            attachments,
            email,
            name,
            phone,
            year,
            make,
            model,
            shop,
            uniqueUserId = null,
            extraParams = {},
        ) => {
            const formData = new FormData();

            formData.append("appointment_time",
                appointmentTime);
            formData.append('appointment_type', appointmentType);

            formData.append("seleted_service_slug", selectedServiceSlug);
            formData.append('selected_service_additional_notes', selectedServiceAdditionalNotes);

            formData.append(
                "requested_service_description",
                requestedServiceDescription
            );


            if (attachments && attachments.length) {
                Array.from(attachments).forEach((attachment, idx) => {
                    if (attachment && attachment != "null") {
                        formData.append(`attachments[${idx}]`, attachment);
                    }
                });
            }

            formData.append("email", email);
            formData.append("name", name);
            formData.append("phone", phone);

            formData.append("year", year);
            formData.append("make", make);
            formData.append("model", model);

            if (uniqueUserId) {
                formData.append("unique_user_id", uniqueUserId);
            }

            if (!shop) {
                shop = 'shopgenie';
            }

            if (Object.keys(extraParams).length) {
                Object.entries(extraParams).forEach(([key, value]) => {
                    formData.append(key, value);
                });
            }

            return apiClient.post(`/public/accounts/${shop}/repair-requests`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        }
    }
};