<template>
  <div class="shopgenie-footer-wrapper">
    <div class="icon-wrapper">
      <svg
        width="24"
        height="14"
        viewBox="0 0 24 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.2166 0.00012207H8.28372V1.78168H9.82515V3.11721L7.24966 3.11655V1.36099L4.85158 1.01185L4.28162 0.954182C2.81682 0.805967 1.48499 1.81088 1.22547 3.26014L0.892578 5.11912L5.4681 6.0233L5.46816 10.9753H9.82515V12.1639H7.40009V13.9454H14.1002V12.1639H11.6067V10.9753H13.3055C15.0047 10.9753 16.5741 10.0662 17.4195 8.59221L19.149 5.57667L21.1686 4.94553L21.9011 5.61943L23.1073 4.30832L21.6183 2.93846L18.3245 3.96782L14.5093 4.7756L12.6817 3.11795L11.6067 3.11767V1.78168H13.2166V0.00012207ZM5.46815 2.90196V3.11609L5.46808 4.20729L2.95465 3.7106L2.97908 3.57418C3.07446 3.04155 3.56393 2.67222 4.10228 2.72669L4.63348 2.78044L5.46815 2.90196Z"
          fill="#757E91"
        />
      </svg>
    </div>
    <div class="footer-text">Powered by</div>
    <a href="https://www.shopgenie.io/" target="_blank">Shopgenie</a>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>