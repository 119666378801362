<template>
  <el-dialog
      class="base-dialog diagnostic-info-dialog no-header"
      @close="close"
      :visible="visible"
  >
    <div class="dialog-title with-mb">How our process works</div>

    <div class="dialog-text-normal">
      Book your appointment in &lt;60 seconds.
    </div>

    <div class="steps-wrapper">
      <div class="step-item">
        <div class="step-number">1</div>
        <div class="step-title">Give us some more info</div>
        <div class="step-text">
          Provide more details so our technicians can take a look ahead of time.
        </div>
      </div>

      <div class="step-item">
        <div class="step-number">2</div>
        <div class="step-title">Drop vehicle off for diagnostic</div>
        <div class="step-text">
          Bring your car to the shop at your chosen day/time so we can look at the problem in-depth.
        </div>
      </div>

      <div class="step-item">
        <div class="step-number">3</div>
        <div class="step-title">Get a detailed quote</div>
        <div class="step-text">
          We'll call/text you with a detailed quote for your repair.
        </div>
      </div>
    </div>

    <button @click.prevent="close" class="dialog-btn-primary">
      Let's go!
    </button>
  </el-dialog>
</template>

<script>
export default {
  name: "DiagnosticInfoDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>