<template>
  <a class="show-slots-btn" :class="{ 'hide-with-opacity': isHidden }" href="#">
    <span>Pick another time</span>
    <i class="el-icon-arrow-down"></i>
  </a>
</template>

<script>
export default {
  name: "PickAnotherTimeBtn",
  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>