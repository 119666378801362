<template>
  <div class="slot-picker-wrapper">
    <el-row :gutter="12">
      <el-col :span="12" v-for="(slot, slotIdx) in allTimeSlots" :key="slotIdx">
        <div
          class="slot"
          :class="{
            'slot-selected': selectedSlot === slot.time,
            'slot-unavailable': !slot.available,
          }"
          @click="$emit('slot-selected', slot)"
        >
          {{ slot.time }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "AvailabilitySlots",
  props: {
    selectedDate: {
      type: String,
      required: true,
    },
    selectedSlot: {
      type: String,
      required: false,
    },
    allTimeSlots: {
      type: Array,
      required: true,
    },
  },
};
</script>
