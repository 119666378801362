<template>
  <div class="wizard-custom-heading">
    <div class="heading-left-wrapper">
      <a
        href="#"
        @click.prevent="handleBackBtnClicked"
        class="heading-btn"
        v-if="showPrevBtn"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0342 15.4981C11.3417 15.8056 11.8403 15.8056 12.1478 15.4981C12.4554 15.1906 12.4554 14.6919 12.1478 14.3844L6.75282 8.98938L12.1478 3.59435C12.4554 3.28681 12.4554 2.78819 12.1478 2.48065C11.8403 2.17312 11.3417 2.17312 11.0342 2.48065L5.08283 8.43198V8.43198C5.04439 8.47042 5.0102 8.5124 4.98137 8.55607C4.77954 8.86174 4.81318 9.27713 5.08228 9.54622V9.54622L11.0342 15.4981Z"
            fill="#444A55"
          />
        </svg>
      </a>
      <div class="heading-text">{{ title }}</div>
    </div>

    <a href="#" class="heading-btn" @click.prevent="closeModal">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.49127 14.3952C2.18374 14.7027 2.18374 15.2013 2.49127 15.5088C2.79881 15.8164 3.29743 15.8164 3.60497 15.5088L9 10.1138L14.395 15.5088C14.7026 15.8164 15.2012 15.8164 15.5087 15.5088C15.8163 15.2013 15.8163 14.7027 15.5087 14.3952L10.1137 9.00012L15.5087 3.60509C15.8163 3.29755 15.8163 2.79893 15.5087 2.4914C15.2012 2.18386 14.7026 2.18386 14.395 2.4914L9 7.88643L3.60497 2.4914C3.29743 2.18386 2.79881 2.18386 2.49127 2.4914C2.18374 2.79893 2.18374 3.29755 2.49127 3.60509L7.8863 9.00012L2.49127 14.3952Z"
          fill="#444A55"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "CustomWizardHeading",
  props: {
    title: {
      type: String,
      required: true,
    },
    isMso: {
      type: Boolean,
      default: true,
    },
    activeIndex: {
      required: true,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    displayedSecondStepSubstep: {
      type: String,
      default: "",
    },
  },
  computed: {
    showPrevBtn() {
      if (!this.isMso) {
        return (
          this.activeIndex !== 1 ||
          (this.activeIndex === 1 &&
            ["diagnostic-info", "service-additional-notes"].includes(
              this.displayedSecondStepSubstep
            ))
        );
      }

      return this.activeIndex && +this.activeIndex !== 0;
    },
  },
  methods: {
    closeModal() {
      window.parent.postMessage(
        {
          name: "searchgenie.closeModal",
        },
        "*"
      );
    },
    handleBackBtnClicked() {
      if (this.activeIndex) {
        this.$emit("prev-tab");
        return;
      }
    },
  },
};
</script>
