<template>
  <div class="add-info-substep">
    <div class="claim-offer-form-wrapper">
      <form action="">
        <div class="form-group">
          <label class="form-label">Full name</label>
          <el-input
            placeholder="Luke Skywalker"
            :value="name"
            @input="(val) => $emit('name-input', val)"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Phone number</label>
          <el-input
            placeholder="(123) 456-7890"
            type="phone"
            :value="phone"
            @input="(val) => $emit('phone-input', val)"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Email address</label>
          <el-input
            placeholder="name@email.com"
            type="email"
            :value="email"
            @input="(val) => $emit('email-input', val)"
          />
        </div>

        <div class="form-group">
          <el-checkbox class="terms-checkbox gray-text" v-model="terms">
            I agree to receive SMS and email notifications about my appointment
            from {{ accountName }}. Messaging and data rates may apply. Reply
            STOP to opt-out at any time. Our
            <a href="https://www.shopgenie.io/terms" target="_blank"
              ><span class="text-primary">Terms & Conditions</span></a
            >.
          </el-checkbox>
        </div>
      </form>
    </div>

    <div class="sticked-to-bottom-button-wrapper">
      <div class="content-container">
        <button
          type="button"
          class="primary-btn full-width submit-contact-info"
          :disabled="!name || !email || !phone || !terms || loading"
          @click.prevent="verifyAndSubmit"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import AppointmentsService from "@/services/AppointmentsService";

export default {
  name: "CustomerInfoStep",
  data() {
    return {
      dialogVisible: true,
      terms: false,
      errorMessage: "",
      loading: false,
    };
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
    accountSlug: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
  },
  methods: {
    async verifyAndSubmit() {
      let isPhoneValid = true;
      this.errorMessage = "";
      // regex verify email
      const isEmailValid = this.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (!isEmailValid) {
        this.errorMessage = "The e-mail you enter is invalid";
        return;
      }

      let recognisedVehicles = [];

      try {
        this.loading = true;
        const {
          data: { recognised_vehicles },
        } = await AppointmentsService.get.recognisedCustomerVehicles(
          this.accountSlug,
          this.email,
          this.phone
        );
        recognisedVehicles = recognised_vehicles.map((vehicle) => ({
          ...vehicle,
          slug: _.uniqueId(),
        }));
        this.loading = false;
      } catch (e) {
        this.loading = false;
        isPhoneValid = false;
        this.errorMessage = "Please enter a valid US phone number";
        return;
      }

      if (
        this.name &&
        this.email &&
        this.phone &&
        this.terms &&
        isPhoneValid &&
        isEmailValid
      ) {
        this.$emit("next-step", recognisedVehicles);
      }
    },
  },
};
</script>
<style lang="scss">
.error-message {
  color: #c52020;
  font-size: 12px;
  margin-top: 5px;
}

.terms-checkbox {
  .el-checkbox__label {
    white-space: break-spaces;
  }

  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }

  .el-checkbox__inner::after {
    left: 5px;
    height: 8px;
  }
}
</style>