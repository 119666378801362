<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 9C8.5 8.44772 8.94772 8 9.5 8H23.5C24.0523 8 24.5 8.44772 24.5 9V20.065C24.5 20.6173 24.0523 21.065 23.5 21.065H23.0714C22.5191 21.065 22.0714 21.5127 22.0714 22.065V27C22.0714 27.5523 21.6237 28 21.0714 28H11.9286C11.3763 28 10.9286 27.5523 10.9286 27V22.065C10.9286 21.5127 10.4809 21.065 9.92857 21.065H9.5C8.94771 21.065 8.5 20.6173 8.5 20.065V9Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 17V12H20.5V17H12.5Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 7.5C16.5 4.5 17.5 3 19.5 3"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>