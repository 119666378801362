<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9332 14.6123L25.5141 12.6303L19.7762 18.3559C18.9083 19.1426 17.779 19.5786 16.6076 19.5793H6.82025C6.51132 19.5793 6.20545 19.5181 5.92034 19.3991C5.63523 19.2802 5.37653 19.1059 5.15923 18.8863C4.94192 18.6667 4.77031 18.4062 4.65433 18.1199C4.53834 17.8335 4.48029 17.527 4.48353 17.2181V11.4681C4.48191 11.3417 4.50539 11.2163 4.55262 11.0991C4.59985 10.9819 4.66989 10.8753 4.75866 10.7854C4.84744 10.6955 4.95318 10.6241 5.06976 10.5754C5.18634 10.5266 5.31144 10.5016 5.43779 10.5016H15.6411C16.0407 10.4975 16.4325 10.6114 16.7677 10.8291C17.1028 11.0467 17.3663 11.3583 17.5252 11.725L18.1613 13.3399C18.2426 13.5206 18.3817 13.6692 18.5566 13.7622C18.7316 13.8553 18.9326 13.8874 19.1278 13.8537L25.5141 12.6303"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.92456 7.66406H14.6012"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7629 7.66406V10.5024"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.48354 7.66406L2 10.1476"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5452 16.9873C27.5452 16.9873 25.0984 19.6788 25.0984 21.881C25.0807 22.2069 25.1319 22.533 25.2486 22.8378C25.3653 23.1427 25.545 23.4196 25.7758 23.6504C26.0066 23.8812 26.2835 24.0608 26.5884 24.1776C26.8932 24.2943 27.2192 24.3455 27.5452 24.3278C27.8736 24.3557 28.2041 24.3116 28.5136 24.1985C28.8231 24.0854 29.1042 23.906 29.3373 23.673C29.5703 23.44 29.7496 23.1589 29.8627 22.8494C29.9758 22.5398 30.0199 22.2093 29.992 21.881C29.992 19.5809 27.5452 16.9873 27.5452 16.9873Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>