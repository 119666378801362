<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.17007 1.17007C1.39682 0.943311 1.76447 0.943311 1.99122 1.17007L7.00001 6.17885L12.0088 1.17008C12.2355 0.943323 12.6032 0.943323 12.8299 1.17008C13.0567 1.39683 13.0567 1.76448 12.8299 1.99123L7.82116 7L12.8299 12.0088C13.0567 12.2355 13.0567 12.6032 12.8299 12.8299C12.6032 13.0567 12.2355 13.0567 12.0088 12.8299L7.00001 7.82115L1.99122 12.8299C1.76447 13.0567 1.39682 13.0567 1.17007 12.8299C0.943311 12.6032 0.943311 12.2355 1.17007 12.0088L6.17885 7L1.17007 1.99122C0.943311 1.76446 0.943311 1.39682 1.17007 1.17007Z"
      fill="#337AFF"
      stroke="#337AFF"
      stroke-width="0.4"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "TimesSvg",
};
</script>