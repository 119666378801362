<template>
  <div class="select-vehicle-screen">
    <div v-if="hasHistory">
      <el-collapse-transition>
        <recognised-customer-garage
          v-if="showCustomerGarage"
          :vehicles="recognisedVehicles"
          :selected-vehicle-slug="selectedRecognisedVehicle?.slug"
          @vehicle-selected="(vehicle) => (selectedRecognisedVehicle = vehicle)"
        />
      </el-collapse-transition>

      <button
        class="add-vehicle-button"
        @click.prevent="toggleShowAddNewVehicle"
      >
        <plus-icon v-if="showCustomerGarage" />
        <car-icon v-else />
        <span>
          {{ showCustomerGarage ? "Add new vehicle" : "View all vehicles" }}
        </span>
      </button>
    </div>

    <transition name="fade-faster">
      <div
        v-if="!hasHistory || (hasHistory && showAddNewVehicle)"
        class="select-vehicle-data-dropdowns-wrapper"
        :class="{ 'spacing-top': hasHistory }"
      >
        <select-vehicle-data-custom-dropdown
          title="Select vehicle year"
          options-entity="year"
          :options="availableYears"
          :selected-option="year"
          :disabled="!availableYears.length"
          :min-value="shopMinYearAllowed"
          :min-value-error-text="`Sorry! We don't work on vehicles older than ${shopMinYearAllowed}`"
          @option-selected="handleYearSelected"
          @deselect-option="handleYearDeselected"
        />

        <select-vehicle-data-custom-dropdown
          id="selectMakeDropdown"
          title="Select vehicle make"
          options-entity="make"
          :with-search="true"
          :options="availableMakes"
          :selected-option="make"
          :disabled="!availableMakes.length || !year"
          @option-selected="handleMakeSelected"
          @deselect-option="handleMakeDeselected"
        />

        <select-vehicle-data-custom-dropdown
          id="selectModelDropdown"
          title="Select vehicle model"
          options-entity="model"
          :with-search="true"
          :options="availableModels"
          :selected-option="model"
          :disabled="!year || !make"
          @option-selected="handleModelSelected"
          @deselect-option="handleModelDeselected"
        />
      </div>
    </transition>

    <transition name="fade-faster">
      <div
        v-if="selectedRecognisedVehicle || (year && make && model)"
        class="sticked-to-bottom-button-wrapper"
      >
        <div class="content-container">
          <button
            class="primary-btn full-width"
            @click.prevent="handleBookNowBtnClicked"
          >
            Book now
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RecognisedCustomerGarage from "./RecognisedCustomerGarage";
import SelectVehicleDataCustomDropdown from "./SelectVehicleDataCustomDropdown";
import VehiclesService from "@/services/VehiclesService";

import PlusIcon from "./PlusIcon";
import CarIcon from "./CarIcon";

import ShopServicesService from "@/services/ShopServicesService";

export default {
  name: "SelectVehicleStep",
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    recognisedVehicles: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RecognisedCustomerGarage,
    SelectVehicleDataCustomDropdown,
    PlusIcon,
    CarIcon,
  },
  data() {
    return {
      selectedRecognisedVehicle: null,

      showCustomerGarage: true,
      showAddNewVehicle: false,

      availableYears: [],
      year: "",

      availableMakes: [],
      make: "",

      availableModels: [],
      model: "",

      shopMinYearAllowed: 1980,
    };
  },
  async mounted() {
    this.shopMinYearAllowed = parseInt(
      (await ShopServicesService.get.shopExtraInfo(this.accountSlug)).data.data
        .min_year_bookings
    );

    return Promise.all([this.fetchYears(), this.fetchMakes()]);
  },
  computed: {
    hasHistory() {
      return this.recognisedVehicles.length > 0;
    },
  },
  methods: {
    async fetchYears() {
      const {
        data: { data: availableYears },
      } = await VehiclesService.get.years();
      this.availableYears = availableYears;
    },
    async fetchMakes() {
      const {
        data: { data: availableMakes },
      } = await VehiclesService.get.makes();
      this.availableMakes = availableMakes.filter(({ logo }) => logo);
    },
    async fetchModels() {
      if (!this.year || !this.make) return;

      const {
        data: { data: availableModels },
      } = await VehiclesService.get.models(this.make.id);
      this.availableModels = availableModels;
    },

    async toggleShowAddNewVehicle() {
      if (this.showCustomerGarage) {
        this.selectedRecognisedVehicle = null;
        this.showCustomerGarage = false;
        await new Promise((resolve) => setTimeout(() => resolve(true), 300));
        this.showAddNewVehicle = true;
        return;
      }

      this.year = null;
      this.make = null;
      this.model = null;
      this.showAddNewVehicle = false;
      await new Promise((resolve) => setTimeout(() => resolve(true), 250));
      this.showCustomerGarage = true;
    },

    async handleYearSelected(year) {
      this.year = year;
      const nextDropdownWrapperEl = document.querySelector(
        "#selectMakeDropdown .dropdown-header"
      );

      await new Promise((resolve) => setTimeout(() => resolve(true), 150));
      nextDropdownWrapperEl.click();
    },
    handleYearDeselected() {
      this.year = null;
      if (this.make) this.make = null;
      if (this.model) this.model = null;
    },

    async handleMakeSelected(make) {
      this.make = make;
      await this.fetchModels();

      const nextDropdownWrapperEl = document.querySelector(
        "#selectModelDropdown .dropdown-header"
      );

      await new Promise((resolve) => setTimeout(() => resolve(true), 150));
      nextDropdownWrapperEl.click();
    },
    handleMakeDeselected() {
      this.make = null;
      if (this.model) this.model = null;
    },

    handleModelSelected(model) {
      this.model = model;
    },
    handleModelDeselected() {
      this.model = null;
    },

    handleBookNowBtnClicked() {
      if (this.selectedRecognisedVehicle) {
        const { year, make, model } = this.selectedRecognisedVehicle;
        return this.$emit("vehicle-selected", { year, make, model });
      }

      this.$emit("vehicle-selected", {
        year: this.year,
        make: this.make.name,
        model: this.model.name,
      });
    },
  },
};
</script>