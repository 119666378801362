<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 2.76367C9.75 2.34946 9.41421 2.01367 9 2.01367C8.58579 2.01367 8.25 2.34946 8.25 2.76367V8.24976H2.76392C2.3497 8.24976 2.01392 8.58554 2.01392 8.99976C2.01392 9.41397 2.3497 9.74976 2.76392 9.74976H8.25V15.2358C8.25 15.6501 8.58579 15.9858 9 15.9858C9.41421 15.9858 9.75 15.6501 9.75 15.2358V9.74976H15.2361C15.6503 9.74976 15.9861 9.41397 15.9861 8.99976C15.9861 8.58554 15.6503 8.24976 15.2361 8.24976H9.75V2.76367Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
};
</script>

<style>
</style>