<template>
  <div class="general-repair-layout">
    <diagnostic-info-dialog
      :visible="diagnosticDialogVisible"
      @close="diagnosticDialogVisible = false"
    />

    <custom-wizard-heading
      v-if="!showAppointmentBookedScreen"
      :title="headerTitle"
      :active-index="$refs.generalRepairWizard?.activeTabIndex"
      :is-mso="isMSO"
      :displayed-second-step-substep="displayedSecondStepSubstep"
      :is-last-step="showAppointmentBookedScreen"
      @prev-tab="handleHeadingGoToPrevTab"
    />

    <custom-wizard-progress
      v-if="!showAppointmentBookedScreen"
      :total-steps="4"
      :current-step="+$refs.generalRepairWizard?.activeTabIndex || 0"
    />

    <transition name="fade-fastest" mode="out-in">
      <form-wizard
        v-if="!showAppointmentBookedScreen"
        color="#377DFF"
        ref="generalRepairWizard"
        :subtitle="null"
        :title="null"
        shape="circle"
        :hide-buttons="true"
      >
        <tab-content class="fade-in" title="Service">
          <select-location-step @location-selected="handleLocationSelected" />
        </tab-content>

        <tab-content class="fade-in" title="Drop off">
          <select-service-step
            v-if="selectedAccount"
            :key="selectedAccount.slug"
            :displayed-substep="displayedSecondStepSubstep"
            :account-slug="selectedAccount.slug"
            @display-diagnostic-info-substep="
              handleDisplayDiagnosticInfoSubstep
            "
            @display-service-additional-notes-substep="
              handleDisplayServiceAdditionalNotesSubstep
            "
            @service-selected="(payload) => handleServiceSelected(payload)"
            @diagnostic-info-submitted="handleDiagnosticInfoSubmitted"
          />
        </tab-content>

        <tab-content class="fade-in">
          <select-appointment-time-step
            v-if="
              selectedAccount &&
              (selectedService || requestedServiceDescription)
            "
            :account-slug="selectedAccount.slug"
            :key="selectedAccount.slug + selectedService?.slug"
            :waiting-mode-enabled="accountHasWaitingModeEnabled"
            :drop-off-policy="accountDropOffPolicy"
            :appointment-time="appointmentTime"
            :selected-service="selectedService"
            @appointment-date-input="handleAppointmentDateInput"
            @select-appointment-time="selectAppointmentTime"
            @next-step="goToCustomerInfoStep"
          />
        </tab-content>

        <tab-content class="fade-in" title="Customer Info">
          <customer-info-step
            v-if="selectedAccount"
            :account-name="selectedAccount?.name"
            :account-slug="selectedAccount?.slug"
            :name="name"
            @name-input="handleNameInput"
            :email="email"
            @email-input="handleEmailInput"
            :phone="phone"
            @phone-input="handlePhoneInput"
            @next-step="handleCustomerInfoFilled"
          />
        </tab-content>

        <tab-content class="fade-in" title="Vehicle">
          <select-vehicle-step
            v-if="selectedAccount"
            :recognised-vehicles="recognisedVehicles"
            :account-slug="selectedAccount.slug"
            @vehicle-selected="bookAppointment"
          />
        </tab-content>
      </form-wizard>

      <appointment-booked-screen
        v-else
        :account-slug="selectedAccount.slug"
        :appointment-time="appointmentTime"
        :selected-service="selectedService"
        :diagnostic-price="diagnosticPrice"
        :requested-service-description="requestedServiceDescription"
      />
    </transition>

    <shopgenie-footer />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import CustomWizardProgress from "@/components/CustomWizardProgress";
import CustomWizardHeading from "@/components/CustomWizardHeading";

import SelectLocationStep from "@/components/select-location-step/Index";

import SelectServiceStep from "@/components/select-service-step/Index";
import DiagnosticInfoDialog from "@/components/DiagnosticInfoDialog";
import SelectAppointmentTimeStep from "@/components/select-appt-time-step/Index";
import CustomerInfoStep from "@/components/CustomerInfoStep";
import SelectVehicleStep from "@/components/select-vehicle-step/Index";
import AppointmentBookedScreen from "@/components/AppointmentBookedScreen";

import ShopgenieFooter from "@/components/ShopgenieFooter";

import AppointmentsService from "@/services/AppointmentsService";

export default {
  name: "GeneralRepair",
  components: {
    CustomWizardProgress,
    CustomWizardHeading,
    FormWizard,
    TabContent,
    SelectLocationStep,
    SelectServiceStep,
    DiagnosticInfoDialog,
    SelectAppointmentTimeStep,
    CustomerInfoStep,
    SelectVehicleStep,
    AppointmentBookedScreen,
    ShopgenieFooter,
  },
  data() {
    return {
      isMSO: true,

      selectedAccount: null,

      headerTitle: "Find a shop",

      displayedSecondStepSubstep: "select-service", // select-service | diagnostic-info | service-additional-notes

      selectedService: null,
      selectedServiceAdditionalNotes: "",
      selectedServiceAttachment: null,

      requestedServiceDescription: "",
      diagnosticDialogVisible: false,
      attachments: null,

      appointmentTime: "",
      appointmentType: "",

      name: "",
      email: "",
      phone: "",
      recognisedVehicles: [],

      showAppointmentBookedScreen: false,
      diagnosticPrice: {
        from: 0,
        to: 0,
      },
    };
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.generalRepairWizard?.activeTabIndex;
      },
      (stepNumber) => {
        const headerTitles = [
          "Find a shop",
          "Book an appointment",
          "Schedule appointment",
          "Add contact info",
          "Add vehicle info",
        ];

        if (
          (!this.isMSO && +stepNumber === 1) ||
          (this.isMSO && +stepNumber === 0)
        ) {
          this.headerTitle =
            this.selectedAccount?.name || headerTitles[stepNumber];
          return;
        }

        if (+stepNumber === 4 && this.recognisedVehicles.length) {
          this.headerTitle = `Welcome back ${this.name
            .split(" ")
            .filter(Boolean)
            .at(0)}!`;
          return;
        }

        this.headerTitle = headerTitles[stepNumber];
      }
    );
  },
  computed: {
    accountHasWaitingModeEnabled() {
      if (
        !this.selectedAccount ||
        !this.selectedAccount.waiting_mode_settings
      ) {
        return false;
      }

      const settings = this.selectedAccount?.waiting_mode_settings?.value;

      if (!settings) return false;

      return settings.enabled;
    },
    accountDropOffPolicy() {
      if (!this.selectedAccount || !this.selectedAccount.drop_off_settings) {
        return false;
      }

      const settings = this.selectedAccount?.drop_off_settings?.value;

      if (!settings) return false;

      return settings.policy;
    },
  },
  methods: {
    getLocationSearchUtmParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);

      return Array.from(urlSearchParams.keys()).reduce((acc, val) => {
        if (!val.startsWith("utm_")) return acc;

        return {
          ...acc,
          [val]: urlSearchParams.get(val),
        };
      }, {});
    },
    handleHeadingGoToPrevTab() {
      if (
        this.$refs.generalRepairWizard.activeTabIndex === 1 &&
        ["diagnostic-info", "service-additional-notes"].includes(
          this.displayedSecondStepSubstep
        )
      ) {
        this.displayedSecondStepSubstep = "select-service";
        this.headerTitle = "Book an appointment";
        return;
      }

      this.$refs.generalRepairWizard.prevTab();
    },

    goToNextStep() {
      this.$refs.generalRepairWizard.nextTab();
    },

    handleLocationSelected(location, isMSO = true) {
      this.selectedAccount = location;
      this.isMSO = isMSO;
      this.goToNextStep();
    },

    handleDisplayDiagnosticInfoSubstep() {
      this.displayedSecondStepSubstep = "diagnostic-info";
      this.headerTitle = "Diagnostic information";
      this.diagnosticDialogVisible = true;
    },

    handleDisplayServiceAdditionalNotesSubstep() {
      this.displayedSecondStepSubstep = "service-additional-notes";
      this.headerTitle = "Anything else we should know?";
    },

    handleServiceSelected({ service, description = "", selectedFile = null }) {
      if (this.requestedServiceDescription) {
        this.requestedServiceDescription = "";
        this.attachments = null;
      }

      this.selectedService = service;
      this.selectedServiceAdditionalNotes = description;
      this.selectedServiceAttachment = selectedFile;

      this.$refs.generalRepairWizard.nextTab();
    },

    handleDiagnosticInfoSubmitted({ description, selectedFile }) {
      if (this.selectedService) {
        this.selectedService = null;
        this.selectedServiceAdditionalNotes = "";
        this.selectedServiceAttachment = null;
      }

      this.requestedServiceDescription = description;
      this.attachments = [selectedFile];
      this.$refs.generalRepairWizard.nextTab();
    },

    goToCustomerInfoStep(appointmentType) {
      this.appointmentType = appointmentType;
      this.goToNextStep();
    },

    handleCustomerInfoFilled(recognisedVehicles) {
      this.recognisedVehicles = recognisedVehicles;
      this.goToNextStep();
    },

    handleAppointmentDateInput(date) {
      this.appointmentDate = date;
    },
    amPmTo24Hour(amPmTime) {
      if (!amPmTime.includes("PM") && !amPmTime.includes("AM")) {
        return amPmTime;
      }
      const date = amPmTime.split(" ")[0];
      const time = amPmTime.split(" ")[1];
      const ampm = amPmTime.split(" ")[2];
      const hours = time.split(":")[0];
      const minutes = time.split(":")[1];
      const hours24 =
        ampm === "PM" && parseInt(hours) < 12
          ? Number(parseInt(hours)) + 12
          : parseInt(hours);

      return `${date} ${hours24}:${minutes}`;
    },
    selectAppointmentTime(appointmentTime) {
      this.appointmentTime = this.amPmTo24Hour(appointmentTime);
    },
    handleNameInput(name) {
      this.name = name;
    },
    handleEmailInput(email) {
      this.email = email;
    },
    handlePhoneInput(phone) {
      this.phone = phone;
    },

    async bookAppointment({ year, make, model }) {
      const loading = this.$loading({
        lock: true,
        text: "Booking appointment",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.85)",
      });

      let appointmentAttachments = null;

      if (this.attachments) appointmentAttachments = this.attachments;

      if (this.selectedServiceAttachment)
        appointmentAttachments = [this.selectedServiceAttachment];

      const utmParams = this.getLocationSearchUtmParams();

      await AppointmentsService.post.bookAppointment(
        this.appointmentTime,
        this.appointmentType,
        this.selectedService?.slug,
        this.selectedServiceAdditionalNotes,
        this.requestedServiceDescription || null,
        appointmentAttachments,
        this.email,
        this.name,
        this.phone,
        year,
        make,
        model,
        this.selectedAccount.slug,
        this.$route.query?.unique_user_id,
        utmParams
      );

      this.showAppointmentBookedScreen = true;
      loading.close();
    },
  },
};
</script>
