<template>
  <el-skeleton :loading="loading" animated>
    <template #template>
      <div class="service-row">
        <div class="service-row-icon-and-info-wrapper">
          <div class="service-icon-wrapper">
            <el-skeleton-item
              variant="image"
              style="width: 32px; height: 32px; border-radius: 50%"
            />
          </div>

          <div class="service-info">
            <div
              :style="`height: 21px; width: ${generateRandomNumber(65, 80)}px`"
            >
              <el-skeleton-item variant="text" />
            </div>

            <!-- <div
              :style="`height: 18px; width: ${generateRandomNumber(90, 120)}px`"
            >
              <el-skeleton-item variant="text" />
            </div> -->
          </div>
        </div>

        <div class="service-pricing">
          <div
            :style="`height: 21px; width: ${generateRandomNumber(35, 50)}px`"
          >
            <el-skeleton-item variant="text" />
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="service-row" :class="{ active: active }">
        <div class="service-row-icon-and-info-wrapper">
          <div class="service-icon-wrapper">
            <diagnostic-appointment-icon
              v-if="service?.is_diagnostic_service"
            />

            <twenty-four-hours-icon
              v-else-if="
                !service?.is_diagnostic_service &&
                service.service_icon_name === '24-hours'
              "
            />

            <component v-else :is="`${service.service_icon_name}-icon`" />
          </div>

          <div class="service-info">
            <div class="title">{{ service.service_name }}</div>
            <!-- <div class="timerange">
              {{ service.appt_duration_mins }} minutes appt.
            </div> -->
          </div>
        </div>

        <div class="service-pricing" v-if="pricing != '$0'">{{ pricing }}</div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: "ServiceRow",
  props: {
    service: {
      type: Object,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    pricing() {
      if (this.service.is_diagnostic_service) return "";

      if (
        this.service.price_from === this.service.price_to ||
        !this.service.price_to
      ) {
        return `$${this.service.price_from}`;
      }
      return `$${this.service.price_from} - $${this.service.price_to}`;
    },
  },
  methods: {
    generateRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>