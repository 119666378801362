<template>
  <div class="appointment-booked-screen">
    <lottie-player
      src="/assets/lottie-confetti.json"
      class="lottie-confetti"
      background="transparent"
      speed="1"
      autoplay
    />
    <div class="thank-you-text-wrapper">
      <div class="icon-wrapper">
        <svg
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.8512 15.924C30.5346 15.2405 30.5346 14.1325 29.8512 13.4491C29.1678 12.7657 28.0598 12.7657 27.3764 13.4491L17.6762 23.1492L13.942 19.415C13.2586 18.7316 12.1506 18.7316 11.4672 19.415C10.7837 20.0984 10.7837 21.2065 11.4672 21.8899L16.4388 26.8615C17.1222 27.5449 18.2302 27.5449 18.9137 26.8615L29.8512 15.924Z"
            fill="#29A341"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.6592 2.5C10.9063 2.5 3 10.4063 3 20.1592C3 29.9121 10.9063 37.8184 20.6592 37.8184C30.4121 37.8184 38.3184 29.9121 38.3184 20.1592C38.3184 10.4063 30.4121 2.5 20.6592 2.5ZM6.5 20.1592C6.5 12.3393 12.8393 6 20.6592 6C28.4791 6 34.8184 12.3393 34.8184 20.1592C34.8184 27.9791 28.4791 34.3184 20.6592 34.3184C12.8393 34.3184 6.5 27.9791 6.5 20.1592Z"
            fill="#29A341"
          />
        </svg>
      </div>

      <div class="text-main">Thank you!</div>
      <div class="text-regular">
        We emailed you all the details and notified the shop about your
        appointment.
      </div>
    </div>

    <div class="appointment-booked-box">
      <div class="box-title">Appointment Info</div>

      <div class="box-body">
        <div class="info-rows-wrapper">
          <div class="info-row">
            <div class="row-key">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3636 10.3636C19.3636 16.0909 12 21 12 21C12 21 4.63635 16.0909 4.63635 10.3636C4.63635 8.41068 5.41216 6.53771 6.79311 5.15676C8.17406 3.77581 10.047 3 12 3C13.9529 3 15.8259 3.77581 17.2069 5.15676C18.5878 6.53771 19.3636 8.41068 19.3636 10.3636Z"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.0007 12.8192C13.3563 12.8192 14.4552 11.7203 14.4552 10.3647C14.4552 9.00909 13.3563 7.91016 12.0007 7.91016C10.6451 7.91016 9.54614 9.00909 9.54614 10.3647C9.54614 11.7203 10.6451 12.8192 12.0007 12.8192Z"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="row-value">
              <span>{{ shopInfo.company_address_full }}</span>
            </div>
          </div>

          <div class="info-row">
            <div class="row-key">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3321 20.0529H4.89474C4.39222 20.0529 3.91029 19.8533 3.55496 19.498C3.19962 19.1426 3 18.6607 3 18.1582V6.78976C3 6.28724 3.19962 5.80531 3.55496 5.44998C3.91029 5.09464 4.39222 4.89502 4.89474 4.89502H16.2632C16.7657 4.89502 17.2476 5.09464 17.6029 5.44998C17.9583 5.80531 18.1579 6.28724 18.1579 6.78976V10.5792"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2106 21.0003C19.3034 21.0003 21 19.3037 21 17.2109C21 15.118 19.3034 13.4214 17.2106 13.4214C15.1177 13.4214 13.4211 15.118 13.4211 17.2109C13.4211 19.3037 15.1177 21.0003 17.2106 21.0003Z"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.3684 3V6.78947"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.78949 3V6.78947"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 10.5786H18.1579"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2105 15.7861V17.211L18.1579 18.1583"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="row-value">
              <span>{{ appointmentTimeFormatted }}</span>
            </div>
          </div>

          <div class="info-row">
            <div class="row-key">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.53268 21C5.19992 21.0009 4.87028 20.9357 4.56285 20.8084C4.25542 20.681 3.97631 20.4939 3.74168 20.2579C3.26743 19.7825 3.0011 19.1384 3.0011 18.4669C3.0011 17.7954 3.26743 17.1513 3.74168 16.6759L10.3656 10.0521L10.2797 9.69351C10.0601 8.79074 10.0776 7.84652 10.3307 6.95254C10.5837 6.05856 11.0636 5.2452 11.7237 4.59144C12.2268 4.08544 12.8253 3.68424 13.4844 3.41107C14.1436 3.1379 14.8504 2.99819 15.5639 3.00002C16.3746 3.00084 17.175 3.18177 17.9073 3.52972C17.9376 3.54455 17.9639 3.5664 17.9841 3.59344C18.0043 3.62048 18.0177 3.65193 18.0233 3.68521C18.0292 3.71863 18.027 3.75296 18.0169 3.78536C18.0068 3.81775 17.9891 3.84727 17.9653 3.87144L14.6356 7.20281L16.7968 9.36397L20.1282 6.0326C20.168 5.99268 20.222 5.97016 20.2784 5.96994C20.2905 5.96992 20.3026 5.97109 20.3144 5.97342C20.3477 5.97907 20.3793 5.99253 20.4064 6.01269C20.4335 6.03286 20.4555 6.05917 20.4705 6.08946C20.9535 7.104 21.1107 8.24316 20.9205 9.35059C20.7303 10.458 20.202 11.4795 19.4082 12.2747C18.905 12.7807 18.3064 13.1818 17.6472 13.4549C16.9879 13.728 16.281 13.8675 15.5674 13.8656C15.1425 13.8656 14.7189 13.816 14.3055 13.7176L13.9476 13.6329L7.32543 20.2579C7.09067 20.4942 6.81129 20.6815 6.50353 20.8089C6.19577 20.9363 5.86576 21.0012 5.53268 21V21Z"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
            <div class="row-value">
              <span>{{ selectedServiceDescription }}</span>
            </div>
          </div>

          <div class="info-row">
            <div class="row-key">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9816 16.4765V19.1862C20.9826 19.4377 20.9311 19.6867 20.8303 19.9172C20.7295 20.1477 20.5817 20.3546 20.3964 20.5247C20.211 20.6947 19.9922 20.8242 19.7539 20.9048C19.5156 20.9854 19.2631 21.0153 19.0125 20.9927C16.2331 20.6907 13.5633 19.7409 11.2176 18.2197C9.03517 16.8329 7.18489 14.9827 5.79811 12.8003C4.27161 10.4439 3.32164 7.76107 3.02515 4.96915C3.00258 4.71938 3.03227 4.46764 3.11232 4.22996C3.19237 3.99229 3.32103 3.77389 3.49011 3.58866C3.65919 3.40343 3.86499 3.25544 4.0944 3.15411C4.32381 3.05278 4.57181 3.00032 4.82261 3.00009H7.53233C7.97068 2.99577 8.39564 3.151 8.72801 3.43683C9.06037 3.72267 9.27746 4.11961 9.33881 4.55366C9.45319 5.42084 9.66529 6.27229 9.97109 7.09177C10.0926 7.41507 10.1189 7.76642 10.0469 8.1042C9.97483 8.44198 9.80747 8.75203 9.56463 8.99761L8.41751 10.1447C9.70332 12.406 11.5757 14.2784 13.837 15.5642L14.9841 14.4171C15.2297 14.1742 15.5397 14.0069 15.8775 13.9348C16.2153 13.8628 16.5666 13.8891 16.8899 14.0106C17.7094 14.3164 18.5609 14.5285 19.428 14.6429C19.8668 14.7048 20.2675 14.9258 20.5539 15.2639C20.8404 15.6019 20.9926 16.0335 20.9816 16.4765Z"
                  stroke="#337AFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="row-value">
              <a :href="`tel:${shopInfo.company_phone}`">
                {{ shopInfo.company_phone }}</a
              >
            </div>
          </div>

          <div
            class="info-row"
            v-if="selectedService && pricing_formatted !== '$0'"
          >
            <div class="row-key">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3V21"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0909 6.27295H9.95452C9.19503 6.27295 8.46666 6.57465 7.92962 7.11169C7.39259 7.64873 7.09088 8.3771 7.09088 9.13659C7.09088 9.89607 7.39259 10.6244 7.92962 11.1615C8.46666 11.6985 9.19503 12.0002 9.95452 12.0002H14.0454C14.8049 12.0002 15.5333 12.3019 16.0703 12.839C16.6074 13.376 16.9091 14.1044 16.9091 14.8639C16.9091 15.6233 16.6074 16.3517 16.0703 16.8888C15.5333 17.4258 14.8049 17.7275 14.0454 17.7275H7.09088"
                  stroke="#323B4B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="row-value">
              <span>{{ pricing_formatted }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box-actions">
        <button id="calendar-button" class="primary-btn full-width">
          Add to calendar
        </button>
        <button
          @click="openGeoLink"
          class="primary-btn outline full-width mt-12px"
        >
          Get directions
        </button>
      </div>
    </div>

    <div class="thank-you-screen-text-muted">
      Please note that the minimum time for a repair is 4 hours from drop off.
      The estimated price for repair is only an assessment and not the final
      price.
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ShopServicesService from "@/services/ShopServicesService";
import { atcb_init, atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

export default {
  name: "AppointmentBookedScreen",
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    appointmentTime: {
      type: String,
      default: "",
    },
    selectedService: {
      type: Object,
      required: false,
    },
    diagnosticPrice: {
      type: Object,
      required: true,
    },
    requestedServiceDescription: {
      type: String,
      required: false,
    },
    pricing: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      shopInfo: {},
      eventData: {},
    };
  },
  methods: {
    async openGeoLink() {
      const isIos = /iphone|ipad|ipod/.test(
        window.navigator.userAgent.toLowerCase()
      );
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.shopInfo.company_address_full}&key=AIzaSyBwgk1Ax5tZ6tGodCZHQVyB0O1F8eYhXPQ`;
      try {
        // make GET request and fetch coordinates
        const response = await fetch(url);
        // check if error
        const data = await response.json();
        const coordinates = data.results[0].geometry.location;
        // open maps app
        if (isIos) {
          // redirect user on parent iframe to google
          window.top.location.href = `maps://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}&amp;ll=`;
        } else {
          window.top.location.href = `maps://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}&amp;ll=`;
        }
      } catch (e) {
        alert(e.message);
      }
    },
    sendAnalyticsData() {
      const reportingData = {
        event: "appointmentBooked",
        appointmentService: this.selectedService
          ? this.selectedService.service_name
          : "Diagnostic",
        appointmentValue: Math.round(
          (this.selectedService
            ? this.selectedService.price_from + this.selectedService.price_to
            : this.diagnosticPrice.from + this.diagnosticPrice.to) / 2
        ),
      };
      window.parent.postMessage(
        {
          name: "reporting.apptBookedData",
          payload: reportingData,
        },
        "*"
      );
    },
  },
  computed: {
    appointmentTimeFormatted() {
      return moment(this.appointmentTime).format("YYYY-MM-DD h:mm A");
    },
    pricing_formatted() {
      if (!this.selectedService) {
        return "";
      }
      return this.selectedService.price_from === this.selectedService.price_to
        ? `$${this.selectedService.price_from}`
        : `$${this.selectedService.price_from} - $${this.selectedService.price_to}`;
    },
    selectedServiceDescription() {
      return this.selectedService
        ? this.selectedService.service_name
        : this.requestedServiceDescription;
    },
  },
  async mounted() {
    this.shopInfo = (
      await ShopServicesService.get.shopInfo(this.accountSlug)
    ).data.data;

    this.eventData = {
      name: "Appointment At " + this.shopInfo.name,
      description: this.selectedServiceDescription,
      startDate: moment(this.appointmentTime).format("YYYY-MM-DDTHH:mm:ss"),
      timeZone: this.shopInfo.timezone,
      endDate: moment(this.appointmentTime)
        .add(1, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"),
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo",
      ],
      trigger: "click",
      listStyle: "list",
      iCalFileName: "download_reminder",
    };
    this.$nextTick(() => {
      atcb_init();
    });

    const button = document.querySelector("#calendar-button");
    button.addEventListener("click", () => atcb_action(this.eventData, button));

    this.sendAnalyticsData();
  },
};
</script>
<style lang="css" scoped>
.lottie-confetti {
  width: 100%;
  height: 100%;
  /* transform: scale(1.5) translateY(-20%); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.lottie-confetti {
  pointer-events: none;
}

.atcb-list-wrapper {
  position: fixed;
  left: 50% !important;
  border-radius: 20px;
  overflow: hidden;
  padding: 0 !important;
  top: 50% !important;
  background-color: white !important;
  transform: translate(-50%, -50%) !important;
}

.atcb-list-item {
  width: 200px;
  border-bottom: 1px solid #e5e5e5;
  justify-content: center;
  box-shadow: none !important;
  background-color: white !important;
}
</style>

