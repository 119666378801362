import apiClient from '@/client';

export default {
  get: {
    index: (shop) => {
      return apiClient.get(`/public/accounts/${shop}/services`);
    },
    shopInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}`);
    },
    shopExtraInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}/extra`);
    },
    availabilityData: (slug, interval, appointmentType, daysAhead = 30) => {
      return apiClient.get(`/public/accounts/${slug}/availability?days_ahead=${daysAhead}&interval=${interval}&appointment_type=${appointmentType}`);
    }
  }
};
