<template>
  <div class="garage-vehicle-card" :class="{ active: active }">
    <car-icon />

    <div class="card-data">
      <div class="vehicle-type">{{ vehicle.vehicle_label }}</div>
      <div v-if="vehicle.latest_activity" class="last-visit">
        Last visit: {{ vehicle.latest_activity }}
      </div>
    </div>
  </div>
</template>

<script>
import CarIcon from "./CarIcon";

export default {
  name: "GarageVehicleCard",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CarIcon,
  },
};
</script>

<style>
</style>