<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 470.641 470.641"
    style="enable-background: new 0 0 470.641 470.641"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M425.666,248.77l24.036-2.903c11.937-1.442,20.938-11.596,20.938-23.619c0-13.118-10.673-23.791-23.791-23.791h-16.743
		c-10.232,0-18.975,6.493-22.327,15.575l-21.609-47.312c-0.055-0.119-0.111-0.236-0.171-0.352l-1.638-3.594
		c-8.688-19.038-26.052-33.194-46.45-37.867c-0.947-0.217-1.979-0.428-3.078-0.635c-0.868-23.377-9.874-45.642-25.611-63.049
		c-3.332-3.687-9.023-3.974-12.711-0.641s-3.975,9.025-0.641,12.712c13.572,15.014,21.048,34.44,21.048,54.7
		c0,44.994-36.604,81.599-81.599,81.599s-81.6-36.605-81.6-81.599c0-35.623,22.955-65.968,54.843-77.071l-3.499,2.745
		c-3.911,3.068-4.595,8.725-1.526,12.636c1.775,2.263,4.418,3.446,7.087,3.446c1.943,0,3.901-0.627,5.549-1.919l29.38-23.048
		c2.461-1.931,3.743-5,3.387-8.108c-0.357-3.107-2.302-5.807-5.137-7.129l-33.841-15.788c-4.502-2.1-9.858-0.154-11.961,4.351
		c-2.102,4.504-0.153,9.86,4.352,11.961l4.994,2.33c-40.206,11.784-69.942,48.296-71.552,91.875
		c-1.099,0.207-2.13,0.418-3.075,0.634c-20.396,4.675-37.76,18.831-46.448,37.871l-23.416,51.245
		c-3.355-9.077-12.096-15.566-22.324-15.566H23.791C10.673,198.457,0,209.129,0,222.248c0,12.024,9.002,22.178,20.938,23.619
		l24.035,2.903l-22.989,22.989c-11.174,11.175-16.521,27.187-14.302,42.833l7.863,55.438c2.021,14.249,9.408,26.675,19.912,35.36
		v25.18c0,14.976,12.184,27.16,27.16,27.16h45.04c14.977,0,27.16-12.184,27.16-27.16v-12.229h201.009v12.229
		c0,14.976,12.184,27.16,27.16,27.16h45.028c14.977,0,27.16-12.184,27.16-27.16v-25.176c10.506-8.685,17.895-21.113,19.916-35.364
		l7.863-55.439c2.219-15.647-3.128-31.66-14.302-42.834L425.666,248.77z M399.447,400.341H71.193
		c-18.192,0-33.927-13.07-37.458-30.733h403.169C433.374,387.271,417.639,400.341,399.447,400.341z M341.351,322.325l9.149,29.284
		h-12.466l-9.149-29.284H341.351z M310.027,322.325l9.149,29.284H151.46l9.149-29.284H310.027z M132.601,351.608H120.14
		l9.148-29.284h12.462L132.601,351.608z M101.282,351.608H31.115l-4.154-29.284h83.469L101.282,351.608z M369.359,351.608
		l-9.149-29.284h83.469l-4.154,29.284H369.359z M424.315,222.247c0-3.193,2.598-5.791,5.791-5.791h16.743
		c3.193,0,5.791,2.598,5.791,5.791c0,2.927-2.191,5.398-5.097,5.749l-23.229,2.805V222.247z M354.718,152.873
		c-5.558-0.963-13.028-1.817-22.324-2.552c0.595-2.588,1.084-5.215,1.473-7.875c0.009,0.002,0.018,0.004,0.027,0.006
		C341.621,144.222,348.746,147.858,354.718,152.873z M136.741,142.452c0.01-0.002,0.021-0.005,0.032-0.007
		c0.388,2.659,0.877,5.286,1.472,7.873c-9.32,0.741-16.79,1.597-22.325,2.557C121.891,147.86,129.015,144.223,136.741,142.452z
		 M99.586,176.965c5.564-2.82,11.584-4.866,17.941-6.089c5.711-1.101,14.824-2.108,26.562-2.945
		c15.422,35.091,50.509,59.661,91.231,59.661c40.721,0,75.806-24.568,91.228-59.656c11.711,0.828,20.828,1.834,26.574,2.941
		c6.351,1.222,12.371,3.27,17.941,6.094l27.814,60.878H71.77L99.586,176.965z M18,222.247c0-3.193,2.598-5.791,5.791-5.791h16.743
		c3.193,0,5.791,2.598,5.791,5.791v8.555l-23.229-2.805C20.191,227.646,18,225.174,18,222.247z M63.351,255.849h343.938
		l28.637,28.638c5.35,5.349,8.62,12.422,9.36,19.838H25.354c0.741-7.416,4.011-14.489,9.36-19.838L63.351,255.849z M116.819,430.571
		c0,5.051-4.109,9.16-9.16,9.16h-45.04c-5.051,0-9.16-4.109-9.16-9.16v-15.138c5.604,1.886,11.573,2.909,17.734,2.909h45.626
		V430.571z M417.177,430.571c0,5.051-4.109,9.16-9.16,9.16h-45.028c-5.051,0-9.16-4.109-9.16-9.16v-12.229h45.619
		c6.16,0,12.127-1.022,17.729-2.907V430.571z"
      />
      <path
        d="M210.345,142.372c13.233,0,24-10.766,24-24v-4.667c0-13.234-10.767-24-24-24s-24,10.766-24,24c0,4.97,4.029,9,9,9
		s9-4.03,9-9c0-3.308,2.691-6,6-6s6,2.692,6,6v4.667c0,3.308-2.691,6-6,6c-13.233,0-24,10.766-24,24v10.333c0,4.97,4.029,9,9,9h30
		c4.971,0,9-4.03,9-9s-4.029-9-9-9h-21v-1.333C204.345,145.064,207.036,142.372,210.345,142.372z"
      />
      <path
        d="M241.038,97.54l-4.667,35.751c-0.336,2.568,0.452,5.156,2.16,7.103c1.709,1.947,4.174,3.062,6.764,3.062h21v15.249
		c0,4.97,4.029,9,9,9s9-4.03,9-9v-60c0-4.97-4.029-9-9-9s-9,4.03-9,9v26.751h-10.749l3.34-25.586
		c0.644-4.929-2.83-9.446-7.759-10.089C246.2,89.139,241.681,92.611,241.038,97.54z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>