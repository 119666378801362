<template>
  <div class="select-location-step-wrapper">
    <company-location-card
      v-for="location in locations"
      :key="location.slug"
      :location="location"
      :loading="loading"
      @location-selected="handleLocationSelected"
    />
  </div>
</template>

<script>
import moment from "moment";
import CompanyLocationCard from "@/components/select-location-step/CompanyLocationCard";

import CompanyService from "@/services/CompanyService";

export default {
  name: "SelectLocationStep",
  components: {
    CompanyLocationCard,
  },
  data() {
    return {
      loading: true,
      locations: [
        { slug: "location-1" },
        { slug: "location-2" },
        { slug: "location-3" },
      ],
    };
  },
  mounted() {
    this.fetchLocations();
  },
  computed: {
    companySlug() {
      return this.$route.query.shop || null;
    },
  },
  methods: {
    calculateHaversineDistance(lat1, lon1, lat2, lon2) {
      const R = 3958.8; // Radius of the Earth in miles, use 6371 for km

      const rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
      const rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflon = (lon2 - lon1) * (Math.PI / 180); // Radian difference (longitudes)

      const d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );

      return d;
    },
    getClientCurrentLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { coords } = position;

            resolve({
              lat: coords.latitude,
              lng: coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    async getDistanceCalculatedCompanyLocations(companyLocations) {
      if (!navigator.geolocation) return companyLocations;

      try {
        const currentClientLocation = await this.getClientCurrentLocation();

        const distanceCalculatedLocations = [];
        for (const companyLocation of companyLocations) {
          const response = await this.$http_client.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${companyLocation.company_address}&key=${process.env.VUE_APP_GOOGLE_PLACES_API_KEY}`
          );

          const { lat, lng } = response.data?.results[0]?.geometry?.location;

          distanceCalculatedLocations.push({
            ...companyLocation,
            harvesine_distance: parseFloat(
              this.calculateHaversineDistance(
                currentClientLocation.lat,
                currentClientLocation.lng,
                lat,
                lng
              ).toFixed(2)
            ),
          });
        }

        return distanceCalculatedLocations.sort(
          (a, b) => a.harvesine_distance - b.harvesine_distance
        );
      } catch (error) {
        return companyLocations;
      }
    },
    getFormattedLocations(locations) {
      const currentDay = moment().format("dddd");

      return locations.map((location) => {
        const currentDaySettings = location.work_hours_settings.value.find(
          (day) => day.day === currentDay
        );

        if (!currentDaySettings) {
          return {
            ...location,
            shop_opened_text: "Closed today",
          };
        }

        const { start_hour, end_hour } = currentDaySettings;
        const formattedStartHour = moment(start_hour, "HH:mm").format(
          "hh:mm a"
        );
        const formattedEndHour = moment(end_hour, "HH:mm").format("hh:mm a");

        const currentTime = moment().format("HH:mm");

        if (currentTime >= start_hour && currentTime <= end_hour) {
          return {
            ...location,
            shop_opened_text: `Closes today at ${formattedEndHour}`,
          };
        }

        if (currentTime > end_hour) {
          return {
            ...location,
            shop_opened_text: `Reopens tomorrow at ${formattedStartHour}`,
          };
        }

        if (currentTime < start_hour) {
          return {
            ...location,
            shop_opened_text: `Opens today at ${formattedStartHour}`,
          };
        }

        return {
          ...location,
          shop_opened_text: "Closed today",
        };
      });
    },
    async fetchLocations() {
      const {
        data: { data: companyLocations },
      } = await CompanyService.get.companyAccounts(this.companySlug);

      if (companyLocations.length === 1) {
        this.handleLocationSelected(companyLocations[0], false);
      }

      this.locations = await this.getDistanceCalculatedCompanyLocations(
        this.getFormattedLocations(companyLocations)
      );

      this.loading = false;
    },
    handleLocationSelected(location, isMSO = true) {
      this.$emit("location-selected", location, isMSO);
    },
  },
};
</script>

<style>
</style>