<template>
  <div class="customer-garage-wrapper">
    <div class="title">Which vehicle is this appointment for?</div>

    <div class="garage-vehicles-list">
      <garage-vehicle-card
        v-for="vehicle in vehicles"
        :key="vehicle.slug"
        :vehicle="vehicle"
        :active="selectedVehicleSlug && vehicle.slug === selectedVehicleSlug"
        @click.native="$emit('vehicle-selected', vehicle)"
      />
    </div>
  </div>
</template>

<script>
import GarageVehicleCard from "./GarageVehicleCard";

export default {
  name: "RecognisedCustomerGarage",
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
    selectedVehicleSlug: {
      type: String,
      required: false,
    },
  },
  components: {
    GarageVehicleCard,
  },
};
</script>
