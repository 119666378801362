<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2131 21.9635L24.0315 22.3009C23.9515 22.4525 23.832 22.5795 23.6856 22.6687C23.5393 22.7578 23.3715 22.8056 23.2002 22.8071H21.4003C21.1481 22.8068 20.9062 22.7065 20.7279 22.528C20.5496 22.3496 20.4494 22.1077 20.4492 21.8555"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M21.4482 18.1719L22.3037 18.47"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M22.3043 13.5322L21.4482 13.8309"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M27.2332 7.39998C27.9652 7.39831 28.6681 7.686 29.1889 8.20035C29.4468 8.45536 29.6513 8.75917 29.7906 9.09407C29.9298 9.42896 30.001 9.78823 29.9999 10.1509V21.855C30.001 22.2177 29.9298 22.5769 29.7906 22.9118C29.6513 23.2467 29.4468 23.5505 29.1889 23.8055C28.9343 24.0601 28.6319 24.2619 28.2991 24.3993C27.9663 24.5366 27.6096 24.6069 27.2495 24.6059H25.9503V22.7268C25.9503 22.7172 25.9476 22.7077 25.9426 22.6996C25.9375 22.6914 25.9302 22.6849 25.9216 22.6806L22.3506 20.9444V18.3008H24.5492C24.9208 18.3004 25.2769 18.1526 25.5396 17.8898C25.8023 17.627 25.95 17.2708 25.9503 16.8992V15.0994C25.95 14.7279 25.8023 14.3717 25.5396 14.109C25.2769 13.8463 24.9207 13.6986 24.5492 13.6983H22.3506V11.0818L23.9001 10.3174L25.9216 9.32018C25.9302 9.31577 25.9374 9.30908 25.9424 9.30085C25.9475 9.29262 25.9502 9.28316 25.9503 9.2735V7.39941L27.2332 7.39998Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M20.4482 10.1513C20.4485 9.89914 20.5488 9.6574 20.7271 9.4791C20.9054 9.30079 21.1472 9.20049 21.3993 9.2002H23.1992C23.371 9.20099 23.5393 9.24852 23.6862 9.3377C23.833 9.42687 23.9528 9.55433 24.0327 9.7064L24.2144 10.0467"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M14.6501 8.85156C10.709 8.85156 7.50195 12.0575 7.50195 15.9992C7.50389 17.8945 8.25759 19.7117 9.59768 21.052C10.9378 22.3923 12.7548 23.1463 14.6501 23.1485C15.786 23.1457 16.905 22.873 17.9147 22.3528C18.9245 21.8327 19.7962 21.08 20.458 20.1568C21.3305 18.9466 21.7995 17.4922 21.7983 16.0003C21.7995 14.5086 21.3307 13.0545 20.4586 11.8444C19.7968 10.921 18.925 10.1681 17.9151 9.6478C16.9052 9.12746 15.7862 8.85455 14.6501 8.85156V8.85156Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M23.8692 24.6631C22.6858 25.9215 21.2574 26.9243 19.6719 27.61C18.0865 28.2957 16.3775 28.6498 14.6501 28.6503C7.67569 28.6503 2 22.9752 2 15.9997C2 9.02418 7.67457 3.34961 14.6501 3.34961C16.3775 3.35022 18.0866 3.70431 19.672 4.39012C21.2575 5.07592 22.6859 6.07892 23.8692 7.33739"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M4.90284 19.2452C4.54346 18.2014 4.36078 17.105 4.36232 16.001C4.36015 14.7965 4.57802 13.6018 5.0052 12.4756"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M27.2491 11.1016C27.0242 11.1017 26.8085 11.1911 26.6493 11.3501C26.4902 11.5091 26.4007 11.7248 26.4004 11.9497C26.4007 12.1747 26.4902 12.3905 26.6493 12.5496C26.8084 12.7087 27.0241 12.7982 27.2491 12.7985C27.4741 12.7982 27.6898 12.7087 27.8489 12.5496C28.0081 12.3905 28.0976 12.1747 28.0979 11.9497C28.0976 11.7248 28.008 11.5091 27.8489 11.3501C27.6898 11.1911 27.4741 11.1017 27.2491 11.1016V11.1016Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M27.2491 19.2012C27.0242 19.2013 26.8085 19.2907 26.6493 19.4497C26.4902 19.6087 26.4007 19.8244 26.4004 20.0493C26.4007 20.2744 26.4902 20.4901 26.6493 20.6492C26.8084 20.8083 27.0241 20.8978 27.2491 20.8981C27.4741 20.8978 27.6898 20.8083 27.8489 20.6492C28.0081 20.4901 28.0976 20.2744 28.0979 20.0493C28.0976 19.8244 28.008 19.6087 27.8489 19.4497C27.6898 19.2907 27.4741 19.2013 27.2491 19.2012V19.2012Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M14.6506 18.3008C14.0407 18.2994 13.4561 18.0565 13.0248 17.6252C12.5936 17.1938 12.3508 16.6092 12.3496 15.9992C12.3509 15.3894 12.5938 14.8049 13.025 14.3737C13.4563 13.9424 14.0407 13.6996 14.6506 13.6982C15.2604 13.6996 15.8449 13.9424 16.2762 14.3737C16.7074 14.8049 16.9502 15.3894 16.9516 15.9992C16.9504 16.6092 16.7076 17.1938 16.2764 17.6252C15.8451 18.0565 15.2605 18.2994 14.6506 18.3008V18.3008Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15.4355 11.5176C16.0726 11.6278 16.6786 11.8737 17.2123 12.2386"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M19.1131 15.1064C19.324 16.1675 19.1489 17.2893 18.6182 18.232"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M10.7568 13.6433C11.0912 13.0901 11.5399 12.6147 12.073 12.249"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15.9241 20.3711C15.5101 20.4915 15.0812 20.5521 14.6501 20.5511C14.4365 20.551 14.2232 20.5361 14.0117 20.5066"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.0408 18.7728C10.6451 18.2613 10.3641 17.6707 10.2168 17.041"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>