<template>
  <div
      class="select-vehicle-custom-dropdown"
      :class="{
      'is-disabled': disabled,
      'is-expanded': isExpanded,
      'is-scaled': isScaled,
    }"
  >
    <div v-if="!selectedOption" @click="expandStep" class="dropdown-header">
      <div class="title">{{ title }}</div>
      <chevron-down-svg/>
    </div>

    <div v-else class="dropdown-header option-selected">
      <div class="title">{{ optionsEntity }}</div>
      <div @click="deselectOption" class="selected-option-val-wrapper">
        <div class="value">
          {{
            ["make", "model"].includes(optionsEntity)
                ? selectedOption.name
                : selectedOption
          }}
        </div>
        <times-svg/>
      </div>
    </div>

    <div v-if="isExpanded && withSearch" class="search-input-wrapper">
      <input
          :placeholder="`Search ${optionsEntity}`"
          class="el-input__inner search-input"
          prefix-icon="el-icon-search"
          @input="handleSearchInput"
          :value="searchedOption"
      />
      <i class="el-icon-search"></i>
    </div>

    <el-collapse-transition>
      <div v-if="isExpanded">
        <div
            v-if="['make', 'model'].includes(optionsEntity)"
            class="dropdown-content"
        >
          <div
              class="option"
              :class="{
              'is-selected':
                componentInternalSelectedOption &&
                componentInternalSelectedOption.id === option.id,
            }"
              v-for="(option, optionIdx) in displayedOptions"
              :key="optionIdx"
              @click="selectOption(option)"
          >
            <div class="name">{{ option.name }}</div>
          </div>
        </div>

        <div v-else class="dropdown-content">
          <div
              class="option"
              :class="{
              'is-selected': componentInternalSelectedOption === option,
              'is-disabled': minValue !== null && parseInt(option) < minValue,
              'is-info-text': minValue !== null && option === minValueErrorText,
            }"
              v-for="(option, optionIdx) in displayedOptions"
              :key="optionIdx"
              @click="selectOption(option)"
          >
            <div class="name">{{ option }}</div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import ChevronDownSvg from "./ChevronDownSvg";
import TimesSvg from "./TimesSvg";

export default {
  name: "SelectVehicleDataCustomDropdown",
  components: {
    ChevronDownSvg,
    TimesSvg,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    optionsEntity: {
      type: String,
      required: true,
    },
    selectedOption: {
      type: [String, Number, Object],
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    minValue: {
      type: Number,
      default: null,
    },
    minValueErrorText: {
      type: String,
      default: "Not allowed",
    },
  },
  data() {
    return {
      isExpanded: false,
      isScaled: false,
      // this state is only being used for better ux, doesn't play role in the core functionality
      // should NOT be used anywhere else
      componentInternalSelectedOption: null,

      searchedOption: "",
    };
  },
  computed: {
    displayedOptions() {
      let options = this.options;

      if (
          this.minValue !== null &&
          options.findIndex((option) => option === this.minValue) !== -1 &&
          options.indexOf(this.minValueErrorText) === -1
      ) {
        options.splice(
            options.findIndex((option) => option === this.minValue) + 1,
            0,
            this.minValueErrorText
        );
      }

      if (!this.withSearch) return options;

      return this.options.filter((option) => {
        if (["make", "model"].includes(this.optionsEntity)) {
          return option.name
              .toLowerCase()
              .includes(this.searchedOption.toLowerCase());
        }

        return option.toLowerCase().includes(this.searchedOption.toLowerCase());
      });
    },
  },
  methods: {
    handleSearchInput(ev) {
      this.searchedOption = ev.target.value;
    },
    async expandStep() {
      if (this.isExpanded) return;

      this.isExpanded = true;

      await new Promise((resolve) => setTimeout(() => resolve(true), 200));
      this.isScaled = true;
    },
    async selectOption(option) {
      this.componentInternalSelectedOption = option;
      this.isScaled = false;
      await new Promise((resolve) => setTimeout(() => resolve(true), 75));

      this.isExpanded = false;

      await new Promise((resolve) => setTimeout(() => resolve(true), 350));

      this.searchedOption = "";
      this.$emit("option-selected", option);
    },
    deselectOption() {
      const isAnyOtherOptionExpanded =
          document.querySelectorAll(".select-vehicle-custom-dropdown.is-expanded")
              .length >= 1;
      if (isAnyOtherOptionExpanded) return;
      this.componentInternalSelectedOption = null;
      this.$emit("deselect-option");
    },
  },
};
</script>