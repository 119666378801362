<template>
  <div>
    <div class="drop-off-step">
      <div v-if="waitingModeEnabled">
        <div class="step-title">I would prefer to...</div>

        <div class="appointment-types-list">
          <appointment-type-card
            :title="dropOffPolicy || 'Drop off my vehicle'"
            :description="appointmentTypeAvailabilityDescriptions.drop_off"
            type="drop_off"
            :selected="selectedAppointmentType === 'drop_off'"
            @click.native="() => handleSelectAppointmentType('drop_off')"
          />

          <appointment-type-card
            title="Wait at the shop"
            :description="appointmentTypeAvailabilityDescriptions.waiting"
            type="waiting"
            :selected="selectedAppointmentType === 'waiting'"
            @click.native="() => handleSelectAppointmentType('waiting')"
          />
        </div>
      </div>
      <div v-else class="mb-12px">
        <div class="step-title no-mb">Select a drop off time</div>
        <div v-if="dropOffPolicy" class="step-subtitle">
          {{ dropOffPolicy }}
        </div>
      </div>

      <transition name="fade-fastest" mode="out-in">
        <div v-if="selectedAppointmentType" :key="selectedAppointmentType">
          <div v-if="waitingModeEnabled" class="step-title">Select a time</div>

          <div class="calendar-wrapper">
            <div class="calendar-header">
              <calendar-controls
                :selected-month="selectedMonth"
                :selected-year="selectedYear"
                @prev-week="goToPrevWeek"
                @next-week="goToNextWeek"
              />

              <div class="days-wrapper" ref="daysWrapper">
                <calendar-day
                  v-for="(day, dayIdx) in weekDays"
                  :key="dayIdx"
                  @click.native="selectDate(dayIdx)"
                  :day="day"
                  :week-moving="weekMoving"
                  :is-day-in-the-past="isDayInThePast(dayIdx)"
                  :is-day-unavailable="isDayUnavailable(dayIdx)"
                  :is-day-selected="isDaySelected(dayIdx)"
                />
              </div>
            </div>

            <el-collapse-transition>
              <div v-if="showAvailabilitySlots" class="calendar-body">
                <availability-slots
                  :selected-date="selectedDate"
                  :selected-slot="selectedSlot"
                  :all-time-slots="availableTimeSlots.all"
                  @slot-selected="handleAvailabilityTimeslotSelected"
                />
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </transition>
    </div>

    <transition name="fade-faster">
      <div
        v-if="selectedDate && selectedSlot"
        class="sticked-to-bottom-button-wrapper"
      >
        <div class="content-container">
          <div class="confirm-appt-time-wrapper">
            <div>
              <div class="confirm-appt-time-text">
                {{ formattedSelectedDateAndTimeslot }}
              </div>
              <div class="confirm-appt-type-text">
                {{ formattedSelectedAppointmentTypeText }}
              </div>
            </div>
            <button
              type="button"
              v-if="showAvailabilitySlots"
              :disabled="!selectedSlot"
              class="primary-btn"
              @click="handleConfirmButtonClicked"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import gsap from "gsap";

import AppointmentTypeCard from "@/components/select-appt-time-step/AppointmentTypeCard";
import CalendarControls from "@/components/select-appt-time-step/CalendarControls";
import CalendarDay from "@/components/select-appt-time-step/CalendarDay";
import PickAnotherTimeBtn from "@/components/select-appt-time-step/PickAnotherTimeBtn";
import AvailabilitySlots from "@/components/select-appt-time-step/AvailabilitySlots";

import ShopServicesService from "@/services/ShopServicesService";

export default {
  name: "SelectAppointmentTimeStep",
  components: {
    AppointmentTypeCard,
    CalendarControls,
    CalendarDay,
    PickAnotherTimeBtn,
    AvailabilitySlots,
  },
  props: {
    selectedService: {
      type: Object,
      required: false,
    },
    accountSlug: {
      type: String,
      required: true,
    },
    waitingModeEnabled: {
      type: Boolean,
      default: false,
    },
    dropOffPolicy: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedAppointmentType: null,
      appointmentTypeAvailabilityDescriptions: {
        drop_off: "Available today",
        waiting: "Available today",
      },

      selectedDate: moment().format("YYYY-MM-DD"),
      daysFromNow: 0,
      weekMoving: false,
      availabilityData: [],

      showAvailabilitySlots: false,
      selectedSlot: null,
    };
  },
  async mounted() {
    if (!this.waitingModeEnabled) {
      this.handleSelectAppointmentType("drop_off");
    }

    this.loadAvailabilityForAllTypes();
  },
  computed: {
    selectedMonth() {
      return moment().add(this.daysFromNow, "days").format("MMMM");
    },
    selectedYear() {
      return moment().add(this.daysFromNow, "days").format("YYYY");
    },
    diffWeeksFromCurrent() {
      return this.daysFromNow / 5;
    },
    soonestAvailableTimeSlot() {
      const firstAvailableSlot = Object.values(this.availabilityData).filter(
        (item) => {
          return item.time_slots.some(({ available }) => !!available);
        }
      )[0];

      if (firstAvailableSlot) {
        const firstAvailableSlotTime = firstAvailableSlot.time_slots.find(
          ({ available }) => !!available
        );

        return {
          date: firstAvailableSlot.date,
          time: firstAvailableSlotTime.time,
          formatted: moment(
            `${firstAvailableSlot.date} ${this.convertSlotToRegular(
              firstAvailableSlotTime.time
            )}`
          ).format("dddd, MMMM Do, h:mm A"),
        };
      }

      return {
        date: null,
        time: null,
        formatted: "No slots available",
      };
    },
    weekDays() {
      const days = [];
      for (let i = 0; i < 100; i++) {
        // day = now + i
        const day = moment().add(i, "days");
        days.push({
          label: day.format("ddd"),
          numeric: day.format("DD"),
        });
      }
      return days;
    },
    availableTimeSlots() {
      return {
        all: this.availabilityData[this.selectedDate]?.time_slots?.sort(
          (a, b) => {
            const aTime = this.convertSlotToRegular(a.time);
            const bTime = this.convertSlotToRegular(b.time);
            return aTime > bTime ? 1 : -1;
          }
        ),
        morning: this.availabilityData[this.selectedDate]?.time_slots?.filter(
          (slot) => slot.time.toLowerCase().includes("am")
        ),
        afternoon: this.availabilityData[this.selectedDate]?.time_slots?.filter(
          (slot) => slot.time.toLowerCase().includes("pm")
        ),
      };
    },
    formattedSelectedDateAndTimeslot() {
      if (!this.selectedSlot || !this.selectedDate) return null;

      const formattedDate = moment(this.selectedDate).format("M/D/YY");
      return `${formattedDate} at ${this.selectedSlot}`;
    },
    formattedSelectedAppointmentTypeText() {
      if (!this.selectedAppointmentType) return null;

      const typeToTextMap = {
        drop_off: "Vehicle drop-off",
        waiting: "Wait with vehicle",
      };

      return typeToTextMap[this.selectedAppointmentType];
    },
  },
  methods: {
    async handleSelectAppointmentType(type) {
      if (type === this.selectedAppointmentType) return;
      this.selectedAppointmentType = type;

      this.selectedDate = moment().format("YYYY-MM-DD");
      this.daysFromNow = 0;
      this.selectedSlot = null;
      this.showAvailabilitySlots = false;

      await this.loadAvailability();

      if (!this.soonestAvailableTimeSlot?.date) return;

      this.selectedDate = this.soonestAvailableTimeSlot.date;
      this.showAvailabilitySlots = true;
    },
    goToPrevWeek() {
      if (this.daysFromNow < 5) return;
      this.daysFromNow -= 5;
    },
    goToNextWeek() {
      if (this.daysFromNow >= 95) return;
      this.daysFromNow += 5;
    },
    convertSlotToRegular(time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      // to lower
      AMPM = AMPM.toLowerCase();
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "am" && hours == 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    },
    isDayInThePast(day) {
      const date = moment().add(day, "days");
      return date.isBefore(moment(), "day");
    },
    isDayUnavailable(day) {
      const date = moment().add(day, "days").format("YYYY-MM-DD");
      return !this.availabilityData[date]?.time_slots.some(
        ({ available }) => !!available
      );
    },
    isDaySelected(day) {
      return (
        moment().add(day, "days").format("YYYY-MM-DD") === this.selectedDate
      );
    },
    selectDate(day) {
      if (this.isDayInThePast(day) || this.isDayUnavailable(day)) {
        return;
      }
      if (!this.showAvailabilitySlots) {
        this.showSlots();
      }
      this.selectedDate = moment().add(day, "days").format("YYYY-MM-DD");
      this.selectedSlot = null;
    },
    selectSlot(date, slot) {
      this.selectedSlot = slot;
      const dateTime = date + " " + slot;
      this.$emit("select-appointment-time", dateTime);
    },
    handleAvailabilityTimeslotSelected(slot) {
      // scroll to bottom of page
      // window.scrollTo(0, document.body.scrollHeight);
      this.selectSlot(this.selectedDate, slot.time);
    },
    showSlots() {
      this.showAvailabilitySlots = true;
    },
    async loadAvailability() {
      this.availabilityData = [];

      this.availabilityData = (
        await ShopServicesService.get.availabilityData(
          this.accountSlug,
          this.selectedService?.appt_duration_mins,
          this.selectedAppointmentType
        )
      ).data.data;
    },
    getSoonestAvailableDate(dateObj) {
      return Object.values(dateObj).find(({ time_slots }) => {
        return time_slots.some(({ available }) => !!available);
      })?.date;
    },
    getHumanReadableFormattedAvailability(date) {
      if (!date) return "today";

      const dateMoment = moment(date, "YYYY-MM-DD");

      if (dateMoment.isSame(moment(), "day")) {
        return "today";
      }

      if (dateMoment.isSame(moment().add(1, "day"), "day")) {
        return "tomorrow";
      }

      return dateMoment.format("dddd");
    },
    async loadAvailabilityForAllTypes() {
      const [dropOffAvailabilityResponse, waitingAvailabilityResponse] =
        await Promise.all([
          ShopServicesService.get.availabilityData(
            this.accountSlug,
            this.selectedService?.appt_duration_mins,
            "drop_off",
            7
          ),
          ShopServicesService.get.availabilityData(
            this.accountSlug,
            this.selectedService?.appt_duration_mins,
            "waiting",
            7
          ),
        ]);

      this.appointmentTypeAvailabilityDescriptions = {
        drop_off:
          "Available " +
          this.getHumanReadableFormattedAvailability(
            this.getSoonestAvailableDate(dropOffAvailabilityResponse.data.data)
          ),
        waiting:
          "Available " +
          this.getHumanReadableFormattedAvailability(
            this.getSoonestAvailableDate(waitingAvailabilityResponse.data.data)
          ),
      };
    },
    handleConfirmButtonClicked() {
      this.$emit("next-step", this.selectedAppointmentType);
    },
  },
  watch: {
    daysFromNow(value, oldValue) {
      // put nice easing
      this.weekMoving = value > oldValue ? "right" : "left";
      setTimeout(() => {
        gsap.to(this.$refs.daysWrapper, {
          duration: 0.4,
          xPercent: -(this.diffWeeksFromCurrent / 20) * 100,
          ease: "power3.inOut",
        });
        setTimeout(() => {
          this.weekMoving = false;
        }, 180);
      }, 20);
      // magnify when moving to next week
    },
  },
};
</script>

<style lang="scss">
.confirm-appt-time-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .confirm-appt-time-text {
    font-weight: 500;
    font-size: 14px;
    color: #17191c;
    margin-bottom: 4px;
  }

  .confirm-appt-type-text {
    font-weight: 300;
    font-size: 14px;
    color: #2d3139;
  }

  .primary-btn {
    width: 45%;
  }
}

.drop-off-step {
  padding-top: 16px;
  padding-bottom: 64px;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: #323b4b;
    font-weight: 600;
    font-size: 25px;
    font-style: normal;
    z-index: 60;
    position: relative;
    line-height: 140%;
  }

  .show-slots-btn {
    display: flex;
    align-items: center;
    color: #377aff;
    position: relative;
    z-index: 50;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    transition: opacity 0.15s;

    i {
      margin-left: 8px;
      font-size: 16px;
    }

    &.hide-with-opacity {
      opacity: 0;
    }
  }

  .calendar-wrapper {
    min-height: 110px;
    overflow: hidden;
    background-color: #fff;
    padding: 16px;
    border: 1px solid #f1f2f4;
    border-radius: 12px;
    z-index: 10;

    .calendar-body {
      border-top: 1px solid #e3e5e8;
      padding-top: 20px;
      margin-top: 20px;
    }

    .calendar-controls {
      width: 100%;
      position: relative;
      z-index: 50;
      display: flex;
      justify-content: space-between;

      .control-actions-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }

    .selected-month {
      font-size: 14px;
      color: #17191c;
      font-weight: 500;
    }

    .days-wrapper {
      display: flex;

      justify-content: space-between;
      margin-top: 16px;
      position: relative;
      width: 2000%;

      .day {
        display: flex;
        flex-direction: column;
        width: 14.285714285714285714285714285714%;
        text-align: center;
        justify-content: center;
        align-items: center;
        // quadratic easing
        transition: all 0.22s ease-out;

        &.moving {
          &.central-day {
            transition: all 0.1s cubic-bezier(1, 0.26, 0.63, 1.04);
            transform: scale(1.4);
          }

          &.subcentral-day {
            transition: all 0.16s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transform: scale(1.25);
          }

          &.subsubcentral-day {
            transition: all 0.22s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transform: scale(1.1);
          }
        }

        .day-label {
          color: #757f90;
          font-weight: 500;
          font-size: 12px;
          letter-spacing: 0.03em;
          text-transform: uppercase;

          &.is-selected {
            color: #337aff;
            background: transparent;
          }
        }

        .day-numeric {
          margin-top: 6px;
          color: #17191c;
          font-weight: 400;
          width: 36px;
          cursor: pointer;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 8px;

          transition: all 0.15s;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;

          &:hover {
            background: #f0f4ff;
          }

          &.is-selected {
            background: #337aff;
          }
        }

        .is-selected {
          background: #337aff;
          color: #fff;
        }

        .is-disabled {
          color: #c6cad2;
          pointer-events: none;
          background-color: #fff;
        }
      }
    }
  }

  .next-slot {
    background: #ffffff;
    border: 1px solid #f1f2f4;
    box-shadow: 0px 2px 12px rgba(0, 30, 84, 0.06);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    justify-content: space-between;
    overflow: hidden;

    .next-slot-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &.book-now-slot {
      margin-bottom: 32px;
    }

    .slot-title {
      flex-grow: 1;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
      color: #323b4b;
    }

    .slot-subtitle {
      font-weight: 300;
      font-size: 14px;
    }

    .slot-book-btn {
      background: #337aff;
      border-radius: 8px;
      padding: 12px 16px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        background: #226dff;
      }
    }

    .slot-icon {
      margin-right: 8px;
      margin-top: 2px;
    }
  }

  .slot-picker-wrapper {
    margin-bottom: -12px;
  }

  .slot {
    width: 100%;
    padding: 10px 12px;
    font-style: normal;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #e3e5e8;
    border-radius: 8px;
    text-align: center;
    color: #2d3139;
    margin-bottom: 12px;
    -webkit-tap-highlight-color: transparent !important;
    transition: all 0.15s;

    &:hover {
      background-color: #f0f4ff;
    }

    &.slot-selected {
      background-color: #f0f4ff;
      border-color: #337aff !important;
      color: #337aff;
    }

    &.slot-unavailable {
      color: #c6cad2;
      border-color: #c6cad2;
      pointer-events: none;
    }
  }

  .primary-btn {
    position: relative;
    z-index: 50;
  }
}
</style>


