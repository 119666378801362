<template>
  <div>
    <transition name="fade-faster" mode="out-in">
      <div
          v-if="displayedSubstep === 'select-service'"
          class="select-service-step"
      >
        <div class="services-section">
          <div class="select-diagnostic-appt-wrapper">
            <div class="section-title">Not sure what's wrong?</div>

            <service-row
                @click.native="handleDiagnosticApptClicked"
                :service="diagnosticService"
                :loading="loading"
                :active="
                selectedService &&
                selectedService.slug === diagnosticService.slug
              "
            />
          </div>

          <div class="section-title">Instant Booking</div>

          <div class="services-list">
            <service-row
                v-for="service in services"
                :key="service.slug"
                :service="service"
                :loading="loading"
                :active="selectedService && selectedService.slug === service.slug"
                @click.native="() => handleSelectService(service)"
            />
          </div>

          <transition name="fade-faster">
            <div
                v-if="
                selectedService && selectedService.slug !== 'diagnostic-appointment'
              "
                class="sticked-to-bottom-button-wrapper"
            >
              <div class="content-container">
                <div class="instant-booking-actions-wrapper">
                  <button
                      class="primary-btn outline"
                      @click.prevent="
                      $emit('display-service-additional-notes-substep')
                    "
                  >
                    Add Note
                  </button>
                  <button
                      class="primary-btn"
                      @click.prevent="confirmSelectedService"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <manually-describe-service-step
          key="service_additional_notes"
          form-title="What are your vehicle issues?"
          :is-form-optional="true"
          v-if="displayedSubstep === 'service-additional-notes'"
          @info-submitted="handleServiceAdditionalNotesSubmitted"
      />

      <manually-describe-service-step
          key="diagnostic_info"
          v-else-if="displayedSubstep === 'diagnostic-info'"
          @info-submitted="handleDiagnosticInfoSubmitted"
      />
    </transition>
  </div>
</template>

<script>
import ServiceRow from "@/components/select-service-step/ServiceRow";
import ManuallyDescribeServiceStep from "@/components/select-service-step/ManuallyDescribeServiceStep";

import ShopServicesService from "@/services/ShopServicesService";

export default {
  name: "SelectServiceStep",
  components: {
    ServiceRow,
    ManuallyDescribeServiceStep,
  },
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    displayedSubstep: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      services: [
        {
          slug: "service-1",
        },
        {
          slug: "service-2",
        },
        {
          slug: "service-3",
        },
      ],
      selectedService: null,
      diagnosticService: {
        slug: "diagnostic-appointment",
      },
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    async fetchServices() {
      const response = await ShopServicesService.get.index(this.accountSlug);

      this.services = response.data.services;

      this.diagnosticService = {
        slug: "diagnostic-appointment",
        appt_duration_mins: response.data.diagnostic_duration_in_min,
        service_name: "Describe Your Issue",
        is_diagnostic_service: true,
        free_diagnostic: response.data.free_diagnostic,
        price_from: response.data.diagnostic_price.from,
        price_to: response.data.diagnostic_price.to,
      };

      this.loading = false;
    },
    handleDiagnosticInfoSubmitted(payload) {
      this.$emit("diagnostic-info-submitted", payload);
    },
    handleSelectService(service) {
      this.selectedService = service;
    },
    confirmSelectedService() {
      this.$emit("service-selected", {service: this.selectedService});
    },
    handleServiceAdditionalNotesSubmitted({description, selectedFile}) {
      this.$emit("service-selected", {
        service: this.selectedService,
        description,
        selectedFile,
      });
    },
    handleDiagnosticApptClicked() {
      this.selectedService = this.diagnosticService;
      this.$emit("display-diagnostic-info-substep");
    },
  },
};
</script>
