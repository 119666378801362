<template>
  <el-skeleton :loading="loading" animated>
    <template #template>
      <div class="company-location-card">
        <div class="location-header" style="height: 24px">
          <el-skeleton-item
              variant="text"
              :style="`width: ${generateRandomNumber(75, 150)}px`"
          />
        </div>

        <div class="location-card-text with-mb">
          <el-skeleton-item
              variant="text"
              :style="`width: ${generateRandomNumber(95, 150)}px`"
          />
        </div>
        <div class="location-card-text">
          <el-skeleton-item
              variant="text"
              :style="`width: ${generateRandomNumber(150, 275)}px`"
          />
        </div>

        <div class="location-card-actions">
          <el-skeleton-item
              variant="button"
              :style="`width: 50%; border-radius: 8px; height: 48px`"
          />
          <el-skeleton-item
              variant="button"
              :style="`width: 50%; border-radius: 8px; height: 48px`"
          />
        </div>
      </div>
    </template>

    <template #default>
      <div class="company-location-card">
        <div class="location-header">
          <div class="location-name">
            {{ location.name }}
          </div>
          <div v-if="location.harvesine_distance" class="location-card-text">
            {{ location.harvesine_distance }} mi away
          </div>
        </div>

        <div class="location-card-text with-mb">
          {{ location.shop_opened_text }}
        </div>

        <div class="location-card-text">
          {{ location.company_address }}
        </div>

        <div class="location-card-actions">
          <a
              href="javascript:;"
              @click="callAction(location.company_phone)"
              class="primary-btn outline"
          >
            Call Us
          </a>
          <button
              class="primary-btn"
              @click.prevent="$emit('location-selected', location)"
          >
            Schedule Now
          </button>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: "CompanyLocationCard",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
  },
  methods: {
    generateRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    callAction(phone) {
      const link = document.createElement('a');
      link.href = `tel:${phone}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  },
};
</script>

<style>
</style>