import Vue from 'vue';
import VueRouter from 'vue-router';
import GeneralRepair from "@/views/GeneralRepair";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'general-repair',
        component: GeneralRepair
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
