<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 440 440"
    style="enable-background: new 0 0 440 440"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M263,355.713c20.52,0,37.213-16.694,37.213-37.213S283.52,281.287,263,281.287s-37.213,16.694-37.213,37.213
		S242.48,355.713,263,355.713z M263,299.287c10.594,0,19.213,8.619,19.213,19.213s-8.619,19.213-19.213,19.213
		s-19.213-8.619-19.213-19.213S252.406,299.287,263,299.287z"
      />
      <path
        d="M440,210.5c0-4.971-4.029-9-9-9h-91.701v-27.088l25.092-19.09c4.933-3.754,11.059-5.822,17.249-5.822H431
		c4.971,0,9-4.029,9-9s-4.029-9-9-9h-49.36c-10.105,0-20.104,3.374-28.149,9.498l-80.207,61.021l-1.5,0.266l-31.404-74.731
		c-8.793-20.926-24.666-38.333-44.694-49.014c-4.39-2.34-9.839-0.68-12.177,3.707c-2.339,4.386-0.68,9.837,3.706,12.176
		c16.389,8.74,29.376,22.982,36.571,40.104l29.828,70.982l-86.222,15.299c-12.59,2.227-23.801,9.666-30.762,20.417l-5.426,8.394
		c-6.021,9.3-9.205,20.075-9.205,31.162c0,8.139,1.69,16.043,5.031,23.51c3.34,7.427,8.122,13.942,14.223,19.37l7.316,6.497
		c9.68,8.604,22.142,13.343,35.09,13.343h21.525c9.695,22.334,31.956,38,57.814,38s48.119-15.666,57.814-38H431c4.971,0,9-4.029,9-9
		s-4.029-9-9-9H325.602c0.255-2.3,0.398-4.633,0.398-7c0-3.022-0.227-5.99-0.64-8.9H431c4.971,0,9-4.029,9-9s-4.029-9-9-9H319.954
		c-10.11-21.318-31.835-36.1-56.954-36.1s-46.844,14.782-56.954,36.1h-64.524c-0.848-2.963-1.344-6.004-1.477-9.1h25.43
		c11.317,0,20.525-9.208,20.525-20.525s-9.208-20.525-20.525-20.525h-3.336c2.608-1.398,5.437-2.396,8.394-2.918L277.793,219.5H431
		C435.971,219.5,440,215.471,440,210.5z M263,273.5c24.813,0,45,20.187,45,45s-20.187,45-45,45s-45-20.187-45-45
		S238.187,273.5,263,273.5z M200.398,325.5H183.66c-8.535,0-16.75-3.124-23.135-8.8l-7.313-6.494
		c-0.222-0.197-0.43-0.405-0.647-0.606h48.075c-0.413,2.909-0.64,5.878-0.64,8.9C200,320.867,200.143,323.2,200.398,325.5z
		 M168,261.975c0,1.392-1.133,2.525-2.525,2.525h-21.946c0.792-1.739,1.71-3.427,2.757-5.05h19.189
		C166.867,259.45,168,260.583,168,261.975z M321.299,201.5h-17.605l17.605-13.394V201.5z"
      />
      <path
        d="M404,235.5c-4.971,0-9,4.029-9,9s4.029,9,9,9h24c4.971,0,9-4.029,9-9s-4.029-9-9-9H404z"
      />
      <path
        d="M140.997,208.43c5.688,3.293,12.201,5.07,19.003,5.07c20.953,0,38-17.047,38-38c0-14.282-7.988-27.027-20.062-33.502
		c2.246-6.074,3.41-12.529,3.41-19.079c0-30.327-24.673-55-55-55s-55,24.673-55,55c0,0.101,0,0.201,0.001,0.302
		c-25.441,7.164-43.726,30.64-43.726,57.748c0,33.084,26.916,60,60,60C110.535,240.968,130.912,228.101,140.997,208.43z
		 M87.624,222.968c-23.159,0-42-18.841-42-42c0-19.3,13.234-35.968,31.513-40.666c1.626-0.418,8.712-1.334,10.487-1.334
		c12.578,0,24.39,5.57,32.407,15.281c1.779,2.156,4.353,3.271,6.945,3.271c2.018,0,4.048-0.675,5.725-2.06
		c3.833-3.164,4.375-8.837,1.211-12.67c-11.07-13.41-27.215-21.267-44.515-21.773c0.993-19.522,17.187-35.098,36.952-35.098
		c20.401,0,37,16.598,37,37c0,6.865-1.897,13.569-5.486,19.388c-1.553,2.517-1.77,5.636-0.58,8.343
		c1.188,2.708,3.632,4.658,6.536,5.218C173.194,157.677,180,165.934,180,175.5c0,11.028-8.972,20-20,20
		c-6.206,0-11.959-2.817-15.785-7.73c-2.116-2.718-5.602-3.988-8.969-3.274c-3.37,0.715-6.036,3.291-6.866,6.634
		C123.723,209.876,106.964,222.968,87.624,222.968z"
      />
      <path
        d="M59,88c0-16.267-13.233-29.5-29.5-29.5S0,71.733,0,88s13.233,29.5,29.5,29.5S59,104.267,59,88z M18,88
		c0-6.341,5.159-11.5,11.5-11.5S41,81.659,41,88s-5.159,11.5-11.5,11.5S18,94.341,18,88z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>