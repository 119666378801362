<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4852 6.20954C20.3062 5.53832 19.6984 5.07129 19.0037 5.07129H7.03578C6.59635 5.07129 6.17808 5.25982 5.88705 5.58899L1.46467 10.591C1.21686 10.8712 1.08008 11.2325 1.08008 11.6066V15.538C1.08008 16.3848 1.76657 17.0713 2.61341 17.0713H4.20504C4.54765 18.1485 5.55607 18.9288 6.74675 18.9288C7.93742 18.9288 8.94584 18.1485 9.28846 17.0713H14.6793C15.0219 18.1485 16.0303 18.9288 17.221 18.9288C18.6938 18.9288 19.8877 17.7349 19.8877 16.2622C19.8877 14.7894 18.6938 13.5955 17.221 13.5955C16.1763 13.5955 15.2718 14.1963 14.8344 15.0713H9.13339C8.69595 14.1963 7.79151 13.5955 6.74675 13.5955C5.70198 13.5955 4.79755 14.1963 4.36011 15.0713H3.08008V12.0683C3.10586 12.0703 3.13191 12.0713 3.1582 12.0713H16.4121C16.9644 12.0713 17.4121 11.6236 17.4121 11.0713C17.4121 10.519 16.9644 10.0713 16.4121 10.0713H11.7183C11.7369 9.99512 11.7467 9.91554 11.7467 9.83366V7.07129H18.6451L20.7785 15.0713H19.6077C19.7869 15.4297 19.8877 15.8342 19.8877 16.2622C19.8877 16.5442 19.8439 16.8161 19.7627 17.0713H21.3859C22.3934 17.0713 23.127 16.1163 22.8675 15.1429L20.4852 6.20954ZM9.74675 7.07129H7.2461L4.59372 10.0713H9.77515C9.75659 9.99512 9.74675 9.91554 9.74675 9.83366V7.07129ZM17.221 16.9288C17.5892 16.9288 17.8877 16.6304 17.8877 16.2622C17.8877 15.894 17.5892 15.5955 17.221 15.5955C16.8528 15.5955 16.5544 15.894 16.5544 16.2622C16.5544 16.6304 16.8528 16.9288 17.221 16.9288ZM7.41341 16.2622C7.41341 16.6304 7.11493 16.9288 6.74675 16.9288C6.37856 16.9288 6.08008 16.6304 6.08008 16.2622C6.08008 15.894 6.37856 15.5955 6.74675 15.5955C7.11493 15.5955 7.41341 15.894 7.41341 16.2622Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CarIcon",
};
</script>

<style>
</style>